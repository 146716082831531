import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { removeAntLinkStyles } from "@/app/routing/utils";
import { useGeneralState } from "@/shared/stores/app-state";

interface Data {
	theme: string;
}

interface MFEInstanceArgs {
	setNavigate: (navigateFn: (url: string) => void) => void;
	setDataSetter: (setter: (data: Data) => void) => void;
	navigate: (url: string) => void;
	data: Data;
}

interface MFEInstance {
	mount: (
		element: HTMLElement,
		{ setNavigate, navigate, data, setDataSetter }: MFEInstanceArgs
	) => void;
	unmount: (element: HTMLElement) => void;
}

interface MFEProps {
	url: string;
}

let loaded = false;

export const MFE = observer(({ url }: MFEProps) => {
	const [navigate, setNavigate] = useState<((url: string) => void) | null>(null);

	const dataSetterRef = useRef<(data: Data) => void>(() => {});

	const { theme } = useGeneralState();

	const ref = useRef<HTMLDivElement | null>(null);
	const mfeRef = useRef<MFEInstance | null>(null);

	const localNavigate = useNavigate();
	const location = useLocation();

	const currentUrl = location.pathname + location.search;

	const loadApp = () => {
		const element = ref.current;

		if (!element) {
			return;
		}

		removeAntLinkStyles();

		localStorage.setItem("CHARTING_LIBRARY_DOMAIN", import.meta.env.BASE_URL);

		// import old app module and render app within given element
		import(/* @vite-ignore */ url).then((mfe: MFEInstance) => {
			mfeRef.current = mfe;

			mfeRef.current.mount(element, {
				setDataSetter: (dataSetter: (data: Data) => void) => {
					dataSetterRef.current = dataSetter;
				},
				navigate: (url: string) => {
					if (currentUrl !== url) {
						localNavigate(url);
					}
				},
				data: { theme },
				setNavigate,
			});
		});
	};

	useEffect(() => {
		if (loaded) {
			loadApp();

			return;
		}

		const script = document.createElement("script");

		script.onload = () => {
			loadApp();

			loaded = true;
		};

		script.type = "module";
		script.src = url;

		document.body.append(script);
	}, []);

	useEffect(() => {
		navigate?.(currentUrl);
	}, [currentUrl]);

	useEffect(() => {
		dataSetterRef.current({ theme });
	}, [theme]);

	useEffect(() => {
		const element = ref.current;

		return () => {
			if (!element || !mfeRef.current) {
				return;
			}

			mfeRef.current.unmount(element);
		};
	}, []);

	return <div style={{ width: "100%", height: "100%" }} ref={ref} />;
});
