import { NavLink } from "react-router-dom";
import { observer } from "mobx-react-lite";

import LogoIconSvg from "@/shared/assets/images/logo.svg?react";
import { MainRoutes } from "@/shared/routing";
import { useGeneralState } from "@/shared/stores/app-state";

import s from "./logo.module.scss";

export const Logo = observer(() => {
	const { isLightTheme } = useGeneralState();

	return (
		<NavLink to={MainRoutes.Root} className={s.logoButton}>
			<LogoIconSvg className={s.logo} fill={isLightTheme ? "black" : "white"} />
		</NavLink>
	);
});
