import { useEffect, useMemo } from "react";

import { IndicationName, IRouteConfig } from "@/app/routing/types";
import { useLateInitContext } from "@/shared/hooks/use-late-init-context";

import { NavigationMenuContext } from "../../model/navigation-menu/context/navigation-menu";

interface UseLoadIndicationProps {
	party?: string;
	uuid?: string;
	currentTab?: string;
	routeItems: IRouteConfig[];
}

const getAllIndicationInCurrentMenu = (routeItems: IRouteConfig[]) =>
	routeItems.map(({ menuItem }) => menuItem?.indicationName) as IndicationName[];

export const useLoadIndication = ({
	party,
	uuid,
	currentTab,
	routeItems,
}: UseLoadIndicationProps) => {
	const state = useLateInitContext(NavigationMenuContext);

	const filteredItems = routeItems.filter(({ menuItem }) => menuItem?.indicationName);

	const allIndicationInCurrentMenu = useMemo(
		() => getAllIndicationInCurrentMenu(filteredItems),
		[filteredItems]
	);

	useEffect(() => {
		state.loadIndication({ party, uuid, currentTab, allIndicationInCurrentMenu });
	}, [state, party, uuid, currentTab, allIndicationInCurrentMenu]);
};
