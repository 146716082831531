import { observer } from "mobx-react-lite";

import { useGeneralState, useUserState } from "@/shared/stores/app-state";

import { BreadCrumbs } from "./breadcrumbs";
import { HeaderMenu } from "./header-menu";
import { Logo } from "./logo";
import { MobileSideMenu } from "./mobile-side-menu";
import s from "./header.module.scss";

export const Header = observer(() => {
	const { isMobile } = useGeneralState();
	const { isLoggedIn } = useUserState();

	return (
		<header data-testid="header" className={s.header}>
			<div data-testid="logo" className={s.logoWrapper}>
				{isMobile && <MobileSideMenu />}

				<Logo />
			</div>
			{isLoggedIn && (
				<>
					{!isMobile && (
						<div data-testid="breadCrumbs" className={s.breadCrumbs}>
							<BreadCrumbs />
						</div>
					)}
					<div data-testid="user" className={s.user}>
						<HeaderMenu />
					</div>
				</>
			)}
		</header>
	);
});
