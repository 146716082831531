import { useEffect, useState } from "react";
import dayjs from "dayjs";

import { DEFAULT_FULL_DATE_FORMAT } from "@/shared/utils/date-utils";

import s from "./clock.module.scss";

export const Timer = () => {
	const now = dayjs().utc().format(DEFAULT_FULL_DATE_FORMAT);
	const [clock, setClock] = useState<string | null>(null);

	useEffect(() => {
		const tick = setTimeout(() => {
			setClock(now);
		}, 1000);
		return () => clearTimeout(tick);
	}, [clock]);

	return (
		<span data-testid="time" className={s.time}>
			{clock && `${clock} (UTC)`}
		</span>
	);
};
