import { responseHandler } from "@/shared/api/fetch/response-handler";
import { ApiModules, ApiResponseGeneric } from "@/shared/api/types";
import { getApiUrl } from "@/shared/api/utils/get-api-url";
import { Mapper } from "@/shared/types/type-from-gotbit";
import { buildQueryUrl } from "@/shared/utils/url";

import { MarketCheckCEXBotDTO, QueryParams } from "../marketcheck/market-check-bots-list/types";
import { NewTickerGroupDTO, TickerGroupDTO } from "../tickers/types";

import {
	GetActionsRequestParams,
	GetActionsResponse,
	GetConditionsRequestParams,
	GetConditionsResponse,
	GetModulesBaseRequestParams,
	GetModulesRequestParams,
	GetPartiesListResponse,
	GetPartiesMetricsResponse,
	GetStrategiesBaseRequestParams,
	GetStrategiesRequestParams,
	GetStrategiesResponse,
	CreatePartyDTO,
	PartiesListParams,
	StartCEXBot,
	CEXPartyBotDTO,
	DEXPartyBotDTO,
	GetActivePartiesDTO,
	GetActiveGroupsDTO,
} from "./types";

export * from "./accounts";
export * from "./binding";
export * from "./candle-analytics";

export const getPartiesMetrics = async (party: string) =>
	responseHandler.handler<GetPartiesMetricsResponse>({
		url: getApiUrl(ApiModules.Parties, `${party}/track-metrics`),
		method: "GET",
	});

export const getMultiGridUUID = async (party: string) =>
	responseHandler.handler<string[]>({
		url: getApiUrl(ApiModules.Parties, `${party}/multigrinders`),
		method: "GET",
	});

export const createBot = async (data: StartCEXBot, party: string, startConfig: boolean) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.Parties, `${party}/bot?start=${startConfig}`),
		method: "POST",
		data,
	});

const modulesRequestParamsToBaseParams: Mapper<
	GetModulesRequestParams,
	GetModulesBaseRequestParams
> = ({ pagination, filter, sort }) => ({
	...pagination,
	...filter,
	...sort,
});

export const getConditions = async (
	party: string,
	params?: GetConditionsRequestParams
): Promise<ApiResponseGeneric<GetConditionsResponse>> => {
	const queryParams = params ? modulesRequestParamsToBaseParams(params) : undefined;

	const url = buildQueryUrl(`${party}/conditions`, queryParams);

	return responseHandler.handler<GetConditionsResponse>({
		url: getApiUrl(ApiModules.Parties, url),
		method: "GET",
	});
};

export const getActions = async (
	party: string,
	params?: GetActionsRequestParams
): Promise<ApiResponseGeneric<GetActionsResponse>> => {
	const queryParams = params ? modulesRequestParamsToBaseParams(params) : undefined;

	const url = buildQueryUrl(`${party}/actions`, queryParams);

	return responseHandler.handler<GetActionsResponse>({
		url: getApiUrl(ApiModules.Parties, url),
		method: "GET",
	});
};

const strategiesRequestParamsToBaseParams: Mapper<
	GetStrategiesRequestParams,
	GetStrategiesBaseRequestParams
> = ({ pagination, filter, sort }) => ({
	...pagination,
	...filter,
	...sort,
});

export const getStrategies = async (party: string, params?: GetStrategiesRequestParams) => {
	const queryParams = params ? strategiesRequestParamsToBaseParams(params) : undefined;

	const url = buildQueryUrl(`/${party}/strategies`, queryParams);

	return responseHandler.handler<GetStrategiesResponse>({
		url: getApiUrl(ApiModules.Parties, url),
		method: "GET",
	});
};

export const getParties = async () =>
	responseHandler.handler<string[]>({
		url: getApiUrl(ApiModules.Parties, ""),
		method: "GET",
	});

export const addParties = async (data: CreatePartyDTO) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.Parties, ""),
		method: "POST",
		data,
	});

export const getPartyBots = async (party: string) =>
	responseHandler.handler<CEXPartyBotDTO[]>({
		url: getApiUrl(ApiModules.Parties, `/${party}/bots`),
		method: "GET",
	});

export const getPartySwapBots = async (party: string) =>
	responseHandler.handler<DEXPartyBotDTO[]>({
		url: getApiUrl(ApiModules.Parties, `/${party}/swap-bots`),
		method: "GET",
	});

export const getPartyTickerGroups = async (party: string) =>
	responseHandler.handler<TickerGroupDTO[]>({
		url: getApiUrl(ApiModules.Parties, `${party}/tickers`),
		method: "GET",
	});

export const addTickerGroup = async (party: string, data: NewTickerGroupDTO) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.Parties, `${party}/tickers`),
		method: "POST",
		data,
	});

export const getPartiesList = async (params: PartiesListParams) => {
	const url = buildQueryUrl("/indicators", params);

	return responseHandler.handler<GetPartiesListResponse>({
		url: getApiUrl(ApiModules.Empty, url),
		method: "GET",
	});
};

export const getActiveParties = async () =>
	responseHandler.handler<GetActivePartiesDTO>({
		url: getApiUrl(ApiModules.Empty, "active-parties"),
		method: "GET",
	});

export const getActiveGroups = async () =>
	responseHandler.handler<GetActiveGroupsDTO>({
		url: getApiUrl(ApiModules.Empty, "active-groups"),
		method: "GET",
	});

export const getMarketCheckCEXBotsList = async ({ party, from, to }: QueryParams) => {
	const url = buildQueryUrl(`${party}/marketcheck`, { from, to });

	return responseHandler.handler<MarketCheckCEXBotDTO[]>({
		url: getApiUrl(ApiModules.Parties, url),
		method: "GET",
	});
};
