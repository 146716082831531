import { NavLink } from "react-router-dom";
import { Divider, Flex, Tooltip, Typography } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react-lite";

import { IMenuItem, IPathParams } from "@/app/routing/types";
import { useLateInitContext } from "@/shared/hooks/use-late-init-context";

import { AsideContext } from "../model/navigation-menu/context/context";
import { NavigationMenuContext } from "../model/navigation-menu/context/navigation-menu";

import { Indicator } from "./indicator/indicator";
import s from "./aside.module.scss";

export interface MenuItemProps {
	menuItem?: IMenuItem;
	route: string;
	pathParams?: IPathParams;
}

export const MenuItem = observer(({ route, menuItem, pathParams }: MenuItemProps) => {
	const { afterClick } = useLateInitContext(AsideContext);
	const state = useLateInitContext(NavigationMenuContext);

	if (!menuItem) return null;

	const { icon, title, topDivider, to, indicationName } = menuItem;

	const path = to ? to(pathParams || {}) : `/${route}`;

	const indication = state.getIndication(indicationName);

	return (
		<>
			{topDivider && (
				<Flex vertical align="center">
					<Divider className={s.divider} />
					<Typography.Text type="secondary" className={s.dividerTitle}>
						{topDivider}
					</Typography.Text>
				</Flex>
			)}

			<Tooltip overlayClassName={s.menuItemTooltip} placement="right" title={indication?.message}>
				<Flex data-testid="item" align="center" className={s.menuItem}>
					{indication?.statusName && <Indicator statusName={indication.statusName} />}
					<NavLink
						data-testid="link"
						className={({ isActive }) => classNames(s.link, { [s.activeLink]: isActive })}
						to={path}
						onClick={afterClick}
					>
						<Flex align="center" justify="center" className={s.iconWrapper}>
							{icon}
						</Flex>
						<span data-testid="link">{title}</span>
					</NavLink>
				</Flex>
			</Tooltip>
		</>
	);
});
