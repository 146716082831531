import { IRouteConfig } from "@/app/routing/types";
import { getAbilitiesFromLocalStorage } from "@/shared/api/utils/get-token";
import { checkParty } from "@/shared/utils/check-abilities";

export const getBotFullRoutePath = (path: string) => `party/:party/${path}/*`;

export const filterWithAbilitiesRoutes = (routeItems: IRouteConfig[], party: string) => {
	const abilities = getAbilitiesFromLocalStorage();
	return routeItems.filter(({ ability }) =>
		ability ? checkParty(abilities, ability, party) : true
	);
};
