/* eslint-disable no-console */
import * as Sentry from "@sentry/react";

import { showErrorMsg } from "@/shared/ui/ui-toaster";

import { isDevEnv } from "./check-environment";

export enum LogLevel {
	Info = "info",
	Debug = "debug",
	Error = "error",
	Warning = "warn",
}

export interface LoggingOptions {
	log?: boolean;
	toast?: boolean;
}

export interface LogErrorOptions {
	dev?: LoggingOptions;
	prod?: LoggingOptions;
	level?: LogLevel;
}

/**
 * Generic error message getter
 * @param error unknown error
 * @returns error message or stringified error
 */
export const getMessageFromError = (error: unknown) =>
	(error as any)?.message ?? JSON.stringify(error);

export const logError = (
	error: unknown,
	{
		dev = { log: true, toast: true },
		prod = { log: true },
		level = LogLevel.Error,
	}: LogErrorOptions = {}
) => {
	const mode = isDevEnv() ? dev : prod;
	const { log: shouldLog, toast: shouldToast } = mode;

	const message = getMessageFromError(error);

	if (shouldToast) showErrorMsg(message);

	if (shouldLog) logDevError(error, { level, logProd: true });

	Sentry.captureException(error);
};

export interface LogDevErrorOptions {
	level?: LogLevel;
	logProd?: boolean;
}

export const logDevError = (
	error: unknown,
	{ level = LogLevel.Info, logProd }: LogDevErrorOptions = {}
) => {
	if (isDevEnv() || logProd) {
		console[level](error);
	}
};

export interface LogDevOptions {
	level?: LogLevel;
}

export const logDev = (
	params: Parameters<typeof console.log>,
	{ level = LogLevel.Info }: LogDevOptions = {}
) => {
	if (isDevEnv()) {
		console[level](...params);
	}
};
