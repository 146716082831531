import { useNavigate } from "react-router-dom";
import type { MenuProps } from "antd";
import { Button, Dropdown } from "antd";
import { observer } from "mobx-react-lite";

import { refreshToken } from "@/shared/api/endpoints";
import { useGetToken } from "@/shared/hooks/use-get-token";
import { MainRoutes } from "@/shared/routing";
import { useGeneralState, useUserState } from "@/shared/stores/app-state";
import { showSuccessMsg } from "@/shared/ui/ui-toaster";

import s from "./header-menu.module.scss";

const BUG_REPORT_LINK = "https://app.clickup.com/9015009436/v/b/8cnc84w-8775";

const DropDownMenuLabel = (label: string, action?: () => void, color?: string) => (
	<Button className={s.button} style={{ color }} type="link" onClick={action}>
		{label}
	</Button>
);

export const HeaderMenu = observer(() => {
	const { setIsLightTheme, isLightTheme } = useGeneralState();
	const { logOut, login } = useUserState();
	const { token } = useGetToken();
	const navigate = useNavigate();

	const onLogout = () => {
		logOut();
		navigate(MainRoutes.Login);
	};

	const onUpdateAbilities = () =>
		refreshToken(() => showSuccessMsg("Abilities updated successfully"));

	const items: MenuProps["items"] = [
		{ key: 1, label: DropDownMenuLabel("Switch Theme", () => setIsLightTheme(!isLightTheme)) },
		{ key: 2, label: DropDownMenuLabel("Update Abilities", onUpdateAbilities) },
		{ key: 3, label: DropDownMenuLabel("Logout", onLogout) },
		{
			key: 4,
			label: DropDownMenuLabel(
				"Report Bug & Issue",
				() => window.open(BUG_REPORT_LINK, "_blank"),
				token.colorPrimary
			),
		},
	];

	return (
		<Dropdown menu={{ items }} placement="bottomRight" overlayClassName={s.dropdown}>
			<Button className={s.userLogin} type="text">
				{login}
			</Button>
		</Dropdown>
	);
});
