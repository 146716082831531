import { getDowntime } from "../date-utils";

export interface IBotCredential {
	market: string;
	name?: string;
	UUID: string;
	link: string;
}

export const getFormattedTimeNotWork = (time: number, showSecond = true) => {
	const timeNotWork = Number(getDowntime(time).split(":")[0]);
	const maxTimeValue = showSecond ? ">999:59:59" : ">999:59";
	const formattedTime = timeNotWork > 1000 ? maxTimeValue : getDowntime(time, showSecond);

	return formattedTime;
};
