import { useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { Button } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react-lite";

import { useGeneralState } from "@/shared/stores/app-state";

import { Aside } from "../../aside";

import s from "./menu.module.scss";

export const MobileSideMenu = observer(() => {
	const { isMobile } = useGeneralState();

	const [isVisible, setIsVisible] = useState(false);

	const hideMobSideBar = () => {
		if (isMobile) setIsVisible(false);
	};

	return (
		<div className={s.container}>
			<Button
				style={{ zIndex: 2 }}
				size="large"
				type="text"
				onClick={() => setIsVisible(!isVisible)}
			>
				<MenuOutlined />
			</Button>
			{isVisible && (
				// eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
				<div className={s.menuWrapper} onClick={hideMobSideBar} />
			)}

			<div className={classNames(s.asideWrapper, { [s.isActive]: !isVisible })}>
				<Aside afterClick={hideMobSideBar} />
			</div>
		</div>
	);
});
