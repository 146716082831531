import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { IPathParams, IRouteConfig } from "@/app/routing/types";
import { BotURLQueryParams, PartyQueryParams } from "@/shared/routing";

import { MenuItem, MenuItemProps } from "../../ui/menu-item";
import { filterWithAbilitiesRoutes } from "../utils";

import { useGetLastSegment } from "./use-get-party-current-tab";
import { useLoadIndication } from "./use-load-indication";

export const useGetNavigationList = (items: IRouteConfig[], pathParams?: IPathParams) => {
	const currentTab = useGetLastSegment();

	const { party, uuid } = useParams<keyof PartyQueryParams | keyof BotURLQueryParams>();

	const permittedRouteItems = useMemo(
		() => (party ? filterWithAbilitiesRoutes(items, party) : items),
		[party, items]
	);

	useLoadIndication({ party, uuid, currentTab, routeItems: permittedRouteItems });

	return permittedRouteItems.map(({ menuItem, path }) => {
		const menuItemProps: MenuItemProps = {
			route: path,
			menuItem,
			pathParams,
		};

		return <MenuItem key={menuItem?.title} {...menuItemProps} />;
	});
};
