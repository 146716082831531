import { makeAutoObservable, runInAction } from "mobx";

import { apiUrl } from "@/shared/constants/environments";
import { Nominal } from "@/shared/types/type-from-gotbit";
import { showErrorMsg } from "@/shared/ui/ui-toaster";
import { getCurrentUnix, unixToISOString } from "@/shared/utils/date-utils";
import { getFromLocalStorage, setToLocalStorage } from "@/shared/utils/local-storage";
import { getRequestUrl } from "@/shared/utils/url";

import {
	Authentication,
	RequestConfig,
	ResponseData,
	ErrorInfo,
	ErrorStack,
	ApiResponseGeneric,
	ErrorData,
} from "../types";
import { refreshTokenRequestConfig, updateRefreshTokenStorage } from "../utils/auth";
import { checkIsShowError } from "../utils/check-is-show-error";
import { getRefreshToken, getToken, setAccessToken, setRefreshToken } from "../utils/get-token";
import { JSONParseError } from "../utils/json-parse-error";
import { getOuterSizeWindow } from "../utils/window";

const STORAGE_KEY = "errStorage";
const COUNTERS_RESET_INTERVAL_MS = 300000;

type ApiResponseError = Nominal<string, "ResponseError">;

class ResponseHandler {
	private _errors: ErrorStack[] = [];

	private _counters: Record<string, number> = {};

	private _timeoutHandler: ReturnType<typeof setTimeout> | null = null;

	constructor() {
		const storedItems = getFromLocalStorage(STORAGE_KEY);
		if (storedItems?.length) {
			this._errors = JSON.parse(storedItems);
		}
		makeAutoObservable(this);
		window.addEventListener("beforeunload", () => {
			setToLocalStorage(STORAGE_KEY, JSON.stringify(this._errors));
		});
	}

	// toastId: the parameter is specified for repeated requests to avoid duplicate messages
	handler = async <Data = any>(
		req: RequestConfig,
		toastId?: string | undefined
	): Promise<ApiResponseGeneric<Data, ApiResponseError>> => {
		let apiResponse: Response | undefined;

		try {
			const fetchResult = await this._fetchApi<Data>(req);

			// checking if fetch resulted in error (network, parse json, etc.)
			if (fetchResult.isError) {
				this._throwError({
					toastId,
					...fetchResult.data,
				});
				return {
					isError: true,
					data: fetchResult.data.message as ApiResponseError,
				};
			}

			let responseData = fetchResult.data;

			// if original request status = 409 => need to refresh token
			if (responseData.response.status === 409) {
				const token = getRefreshToken();

				if (token) {
					const retryResponse = await this._refreshTokens<Data>(token, req, responseData.response);
					// checking if refresh token request resulted in error
					// (refresh request itself, or retry request)
					if (retryResponse.isError) {
						this._throwError({
							toastId,
							...retryResponse.data,
						});
						return {
							isError: true,
							data: retryResponse.data.message as ApiResponseError,
						};
					}

					responseData = retryResponse.data;
				} else this._logOut();
			}

			// if resulting request (original or refreshed) status = 401
			// => can't refresh token, need to logout
			if (responseData.response.status === 401) {
				this._logOut();
			}

			if (req.validateDataScheme === false) {
				return { isError: false, data: responseData.result };
			}

			// parsing successful response data
			const { status, error, message, success, isError, data } = responseData.result as any;
			apiResponse = responseData.response;

			const isDataError = this._isDataError(success, isError);

			// check if error in response data object
			if (isDataError) {
				const errorMessage = this._getErrorMessage(error || message);

				this._throwError({
					code: status,
					message: errorMessage,
					toastId,
					status: apiResponse.status,
					request: apiResponse.url,
				});
				return { isError: true, data: errorMessage as ApiResponseError };
			}

			// everything ok and response data is valid
			return { isError: false, data };
		} catch (err: unknown) {
			// unknown error happened while fetching
			const errorMessage = this._getErrorMessage(err);

			this._throwError({
				code: "",
				message: errorMessage,
				toastId,
				status: apiResponse?.status ?? null,
				request: apiResponse?.url ?? "",
			});

			return { isError: true, data: errorMessage as ApiResponseError };
		}
	};

	// counter for repeated requests
	private _repeatHandler = (toastId: string) => {
		if (!this._timeoutHandler) {
			this._enableResetTimer();
		}

		if (this._counters[toastId]) {
			this._counters[toastId] += 1;
		} else {
			this._counters[toastId] = 1;
		}
	};

	private _enableResetTimer = () => {
		this._timeoutHandler = setTimeout(() => {
			this._resetCounters();
		}, COUNTERS_RESET_INTERVAL_MS);
	};

	private _resetCounters = () => {
		runInAction(() => {
			this._counters = {};
		});

		runInAction(() => {
			this._timeoutHandler = null;
		});
	};

	private _resetTimeout = () => {
		if (!this._timeoutHandler) return;

		clearTimeout(this._timeoutHandler);
		this._timeoutHandler = null;
	};

	private _getAuthToken = (auth?: Authentication) => {
		if (auth === undefined) return getToken();
		if (auth === false) return undefined;
		if (auth.token) return auth.token;
	};

	private _getAuthHeader = (auth?: Authentication) => {
		const token = this._getAuthToken(auth);
		if (!token) return undefined;
		return { Authorization: `Bearer: ${token}` };
	};

	private _getViewPortParam = () => {
		const sizes = getOuterSizeWindow();

		return {
			"Display-Width": `${sizes.width}`,
			"Display-Height": `${sizes.height}`,
		};
	};

	private _fetchApi = async <Data>({
		data,
		auth,
		baseUrl = apiUrl,
		apiPrefix,
		url,
		method,
		headers,
	}: RequestConfig): Promise<ApiResponseGeneric<ResponseData<Data>, ErrorInfo>> => {
		let response: Response | undefined;

		const requestUrl = getRequestUrl({
			baseUrl: baseUrl ?? "",
			prefix: apiPrefix,
			url,
		});

		const authHeader = this._getAuthHeader(auth);

		const viewPortParams = this._getViewPortParam();

		try {
			const body = data ? JSON.stringify(data) : undefined;

			response = await fetch(requestUrl, {
				method,
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					...authHeader,
					...headers,
					...viewPortParams,
				},
				body,
			});

			const responseData = await this._parseJson<Data>(response);

			// if (response.status !== 200) reportErrorToSentry(data.status, data.error);

			// fetch success => return response with parsed json data
			return { isError: false, data: { response, result: responseData } };
		} catch (err) {
			// error happened during fetch or json parse
			return {
				isError: true,
				data: {
					message: this._getErrorMessage(err),
					request: response?.url ?? requestUrl,
					status: response?.status ?? null,
					code: "",
				},
			};
		}
	};

	private _parseJson = async <Data>(response: Response) => {
		const text = await response.text();
		try {
			const json = JSON.parse(text);
			return json as Data;
		} catch (err) {
			throw new JSONParseError(text);
		}
	};

	private _getErrorMessage = (err: unknown) => {
		if (err instanceof Error) return err.message;
		if (typeof err === "string") return err;
		return "Something went wrong";
	};

	private _isDataError = (success?: boolean, isError?: boolean) => {
		if (isError !== undefined) {
			return isError;
		}

		if (success === undefined) {
			return true;
		}
		if (!success) {
			return true;
		}
		return false;
	};

	private _getPathNameByRequest = (request: string) => {
		try {
			const pathName = new URL(request).pathname;
			return pathName;
		} catch {
			return "";
		}
	};

	private _throwError = (data: ErrorData) => {
		const { message, code, status, request, toastId } = data;
		// if the request has already worked 3 times, then we do not display anything
		// if (this.repeatCounter >= 3) return;
		if (toastId) {
			if (this._counters[toastId] >= 3) return;
		}

		// eslint-disable-next-line quotes
		const notSupp = message.includes('"not supported"');

		if (!notSupp && checkIsShowError(status, request)) {
			const statusCodeMessage = `${code ? "Code" : "Status"}: ${code || status}`;

			showErrorMsg(
				{
					title: statusCodeMessage,
					infoBlocks: [
						{ title: "Request", message: this._getPathNameByRequest(request) },
						{ title: "Message", message },
					],
				},
				{ toastId }
			);
		}

		this._setError(data);
		if (toastId) this._repeatHandler(toastId);
	};

	private _setError = (data: ErrorInfo) => {
		if (this._errors.length === 100) {
			this._errors.shift();
		}

		this._errors.push({
			...data,
			date: unixToISOString(getCurrentUnix()),
		});
	};

	private _refreshTokens = async <Data>(
		token: string,
		originalReq: RequestConfig,
		originalRes: Response
	): Promise<ApiResponseGeneric<ResponseData<Data>, ErrorInfo>> => {
		try {
			const refreshRequestConfig = refreshTokenRequestConfig(token);
			const refreshResult = await this._fetchApi<any>(refreshRequestConfig);

			// error during refresh token request
			if (refreshResult.isError) {
				return {
					isError: true,
					data: {
						...refreshResult.data,
						status: originalRes.status,
						request: originalRes.url,
					},
				};
			}

			// parsing refresh tokens response data
			const refreshResponse = refreshResult.data;

			const { status, error, message, success, isError, data } = refreshResponse.result;

			const isDataError = this._isDataError(success, isError);

			// check if valid response data => update tokens
			if (!isDataError) {
				updateRefreshTokenStorage(data);

				// make original request once again with same config
				const retryData = await this._fetchApi<Data>(originalReq);

				return retryData;
			}
			// logout user if refresh tokens failed
			this._logOut();

			const errMessage = this._getErrorMessage(error || message);

			return {
				isError: true,
				data: {
					message: errMessage,
					code: status,
					status: originalRes.status,
					request: originalRes.url,
				},
			};
		} catch (err) {
			// unknown error happened while refreshing tokens and retrying
			this._logOut();

			const errMessage = this._getErrorMessage(err);

			return {
				isError: true,
				data: {
					message: errMessage,
					code: "",
					status: originalRes.status,
					request: originalRes.url,
				},
			};
		}
	};

	private _logOut = () => {
		setAccessToken("");
		setRefreshToken("");

		this._resetTimeout();
	};
}

export const responseHandler = new ResponseHandler();
