import { useEffect } from "react";
import { BarChartOutlined } from "@ant-design/icons";
import * as Sentry from "@sentry/react";
import { Tooltip } from "antd";
import { observer } from "mobx-react-lite";

import { useConstant } from "@/shared/hooks/use-constant";
import { useUserState } from "@/shared/stores/app-state";

import { wsConnection } from "./model/ws-connection";
import { WSStore } from "./model/ws-store";
import s from "./status-connection.module.scss";

export const StatusConnection = observer(() => {
	const store = useConstant(() => new WSStore());
	const {
		getStatusInfo: { title, color },
		getIntervalHandler,
		getFirstRequest,
		setFirstRequest,
		getDelayTitle,
		setDelay,
	} = store;
	const { logOut } = useUserState();

	useEffect(() => {
		if (getFirstRequest === 0) {
			setFirstRequest(1);
		} else {
			wsConnection(getIntervalHandler, setDelay, logOut);
		}
	}, [getFirstRequest, getIntervalHandler]);

	return (
		<div data-testid="statusConnection" className={s.statusConnection}>
			<Sentry.ErrorBoundary>
				<span data-testid="title" className={s.title}>
					{title}
				</span>
				<Tooltip title={getDelayTitle()} placement="topLeft">
					<BarChartOutlined style={{ color }} />
				</Tooltip>
			</Sentry.ErrorBoundary>
		</div>
	);
});
