import { responseHandler } from "@/shared/api/fetch/response-handler";
import { ApiModules } from "@/shared/api/types";
import { getApiUrl } from "@/shared/api/utils/get-api-url";

import {
	CreateExchangeModuleProps,
	ExchangeModuleProps,
	IGridStateResponse,
	IMultiGridStatus,
	IMultiGrinder,
	MultiGrinderSettings,
	MultiGrinderStateProps,
	UpdExchangeModuleProps,
} from "./types";

export const getMultiGridExist = async (uuid: string) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.MultiGrinder, `${uuid}/exist`),
		method: "GET",
	});

export const createMultiGrinder = async (data: IMultiGrinder) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.MultiGrinder, ""),
		method: "POST",
		data,
	});

export const getMultiGrinderStatus = async (uuid: string) =>
	responseHandler.handler<IMultiGridStatus>({
		url: getApiUrl(ApiModules.MultiGrinder, `${uuid}/status`),
		method: "GET",
	});

export const getMultiGrinderSettings = async (uuid: string) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.MultiGrinder, `${uuid}/settings`),
		method: "GET",
	});

export const updMultiGrinderSettings = async (uuid: string, settings: MultiGrinderSettings) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.MultiGrinder, `${uuid}/settings`),
		method: "PUT",
		data: { settings },
	});

export const startMultiGrinder = async (uuid: string) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.MultiGrinder, `${uuid}/start`),
		method: "POST",
		data: {},
	});

export const stopMultiGrinder = async (uuid: string) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.MultiGrinder, `${uuid}/stop`),
		method: "POST",
		data: {},
	});

export const createExchangeModule = async ({ uuid, data }: CreateExchangeModuleProps) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.MultiGrinder, `${uuid}/exchange`),
		method: "POST",
		data,
	});

export const getExchangesModules = async (uuid: string) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.MultiGrinder, `${uuid}/exchange`),
		method: "GET",
	});

export const updExchangeModule = async ({ uuid, id, data }: UpdExchangeModuleProps) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.MultiGrinder, `${uuid}/exchange/${id}`),
		method: "PUT",
		data,
	});

export const startExchangeModule = async ({ uuid, id }: ExchangeModuleProps) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.MultiGrinder, `${uuid}/exchange/${id}/start`),
		method: "POST",
		data: {},
	});

export const stopExchangeModuleMultiGrinder = async ({ uuid, id }: ExchangeModuleProps) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.MultiGrinder, `${uuid}/exchange/${id}/stop`),
		method: "POST",
		data: {},
	});

export const removeExchangeModuleMultiGrinder = async ({ uuid, id }: ExchangeModuleProps) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.MultiGrinder, `${uuid}/exchange/${id}`),
		method: "DELETE",
	});

export const getMultiGrinderState = async ({ uuid, offset, step }: MultiGrinderStateProps) => {
	const offsetParam = offset !== undefined ? `&offset=${offset}` : "";

	return responseHandler.handler<IGridStateResponse>({
		url: getApiUrl(ApiModules.MultiGrinder, `${uuid}/state?step=${step}${offsetParam}`),
		method: "GET",
	});
};
