import { useLocation, Navigate } from "react-router-dom";

import { MainRoutes } from "@/shared/routing";
import { createRedirectParamUrl } from "@/shared/utils/url/redirect";

export const LoginRedirect = () => {
	const location = useLocation();

	const redirectUrl = createRedirectParamUrl(MainRoutes.Login, location);

	return <Navigate replace to={redirectUrl} />;
};
