import { getFormattedTimeNotWork } from "@/shared/utils/bots";
import { unixToDateFormat } from "@/shared/utils/date-utils";

export const getLastUpdateMsg = (time: number) =>
	`Last update: ${unixToDateFormat(time, "FullDate")}`;

export const geCexSettingsMsg = (timeDontWork?: number, message?: string) => {
	const formattedTime = timeDontWork ? getFormattedTimeNotWork(timeDontWork) : "—";

	return `Downtime: ${formattedTime}\n\nMessage: ${message || "—"}`;
};
