import { createLateInitContext, useLateInitContext } from "@/shared/hooks/use-late-init-context";

import { AbilitiesStore } from "./abilities-store";
import { GeneralStore } from "./general-store";
import { UserStore } from "./user-store";

export const abilitiesStore = new AbilitiesStore();

export const generalStore = new GeneralStore();

export const userStore = new UserStore();

export const appState = {
	abilitiesState: abilitiesStore,
	generalState: generalStore,
	userState: userStore,
};

export const AppContext = createLateInitContext<typeof appState>();

export const useAbilitiesState = () => useLateInitContext(AppContext).abilitiesState;

export const useGeneralState = () => useLateInitContext(AppContext).generalState;

export const useUserState = () => useLateInitContext(AppContext).userState;
