import { Spin, SpinProps } from "antd";
import classNames from "classnames";

import { SpinnerIndicator } from "./spinner-indicator";
import s from "./ui-spinner.module.scss";

export interface UISpinnerProps extends SpinProps {}

export const UISpinner = ({
	wrapperClassName,
	indicator: outerIndicator,
	children = "",
	...props
}: UISpinnerProps) => {
	const indicator = outerIndicator || <SpinnerIndicator className={s.uiSpinnerIndicator} />;

	return (
		<Spin
			wrapperClassName={classNames(s.uiSpinnerWrapper, wrapperClassName)}
			indicator={
				// extra div needed to avoid ant styling the indicator
				<div>{indicator}</div>
			}
			{...props}
		>
			{children}
		</Spin>
	);
};
