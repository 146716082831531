import { theme } from "antd";

import { ColorStatusMap, StatusColorNames } from "../types/status-colors-types";

const { useToken } = theme;

const useColorStatusMap = (): ColorStatusMap => {
	const { token } = useToken();

	return {
		purple: token.purple6,
		yellow: token.gold6,
		blue: token.blue6,
		green: token.green6,
		lightRed: token.red5,
		red: token.red7,
		gray: token.colorFill,
	};
};

export const useGetStatusColor = (status: StatusColorNames) => {
	const colorStatusMap = useColorStatusMap();

	return colorStatusMap[status];
};
