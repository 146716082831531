import { MenuProps } from "antd";
import duration from "dayjs/plugin/duration";

export type DayjsDuration = duration.Duration;

export type MenuItem = Required<MenuProps>["items"][number];

export enum ColorTheme {
	DARK = "dark",
	LIGHT = "light",
}

export type CommonKeys<A, B> = keyof A & keyof B;

export enum WSPingEnum {
	PING = "ping",
	EXCHANGE = "exchange",
}

export type SocketMessageEvent = {
	error: string | null;
	type: "pong";
	result: { id: string; time: number; tokenExpiredSoon?: boolean };
};

export enum BotsEnum {
	CEX = "CEX",
	DEX = "DEX",
	DEXV2 = "DEXV2",
	DEXNE = "DEXNE",
}

export type BotsType = BotsEnum.CEX | BotsEnum.DEX | BotsEnum.DEXV2 | BotsEnum.DEXNE;

export enum BotsStatusesEnum {
	WARNING = "warning",
	ALERT = "alert",
	CLOSED = "closed",
	ALL = "all",
	NEW = "new",
	OLD = "old",
	CRASH = "crash",
	STOPPED = "stopped",
	INITIAL = "initial",
}

export type BotsStatusesType =
	| BotsStatusesEnum.WARNING
	| BotsStatusesEnum.ALERT
	| BotsStatusesEnum.CLOSED
	| BotsStatusesEnum.ALL
	| BotsStatusesEnum.NEW
	| BotsStatusesEnum.OLD
	| BotsStatusesEnum.CRASH
	| BotsStatusesEnum.STOPPED
	| BotsStatusesEnum.INITIAL;

export type LimitVolumeCounterType = "limit" | "volume" | "counter";

export enum InputTypeEnum {
	TEXT = "text",
	PASSWORD = "password",
	NUMBER = "number",
}
export type InputType = InputTypeEnum.TEXT | InputTypeEnum.PASSWORD;

export type ExchangeModuleStatus = "active" | "stopped" | "failed";

export interface Meta {
	[key: string]: string;
}

export type PropertyKeyKeys<T> = {
	[P in keyof T]: T[P] extends PropertyKey ? P : never;
}[keyof T];

export type PropertyKeyValues<T> = {
	[K in keyof T]: T[K] extends PropertyKey ? T[K] : never;
};
