import { InfoOutlined } from "@ant-design/icons";

import { useGetStatusColor } from "@/shared/hooks/use-get-status-color";
import { StatusColorNames } from "@/shared/types/status-colors-types";

import s from "./indicator.module.scss";

interface IndicatorProps {
	statusName: StatusColorNames;
}

export const Indicator = ({ statusName }: IndicatorProps) => {
	const statusColor = useGetStatusColor(statusName);

	return (
		<div className={s.indicatePlate} style={{ backgroundColor: statusColor }}>
			<div className={s.infoIconWrapper}>
				<InfoOutlined />
			</div>
		</div>
	);
};
