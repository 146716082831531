import dayjs, { unix } from "dayjs";

import { CEXBotStatus } from "@/shared/api/endpoints/bot/types";
import { StatusColorNames } from "@/shared/types/status-colors-types";
import { isDefined, Nullable, Nullish } from "@/shared/types/type-from-gotbit";

/**
 * checking that the bot stopped 5 minutes ago
 * @param timeDontWork - unix time
 */
const checkRedStatus = (timeDontWork: number) => {
	const stopDate = unix(timeDontWork);
	const diffMinutes = dayjs().diff(stopDate, "minutes");

	return diffMinutes > 5;
};

export const getCEXBotStatusColorName = (
	status: Nullable<CEXBotStatus>,
	timeDontWork: Nullish<number>
) => {
	const colorStatusesMap: Record<CEXBotStatus, StatusColorNames> = {
		alert: !isDefined(timeDontWork) || checkRedStatus(timeDontWork) ? "red" : "lightRed",

		warning: "yellow",

		paused: "blue",

		active: "green",

		stopped: "gray",

		initial: "gray",

		crash: "purple",
	} as const;

	if (!status || !colorStatusesMap[status]) return "gray";

	return colorStatusesMap[status];
};
