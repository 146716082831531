import { BreadcrumbMenuItem, CrumbHandle } from "@/app/routing/types";
import { useAbilitiesState } from "@/shared/stores/app-state";
import { AbilityType } from "@/shared/types/abilities-types";

const checkMenuAbilities = (
	items: BreadcrumbMenuItem[],
	hasAbility: (ability: AbilityType) => boolean
) => items?.filter(({ ability }) => (ability ? hasAbility(ability) : true));

export const useCheckBreadcrumbsAbilities = (crumbs: CrumbHandle["crumb"][]) => {
	const { hasAbility } = useAbilitiesState();

	return crumbs?.map(({ menu, ...restProps }) => {
		const menuItems = menu?.items;

		return {
			menu: menuItems ? { items: checkMenuAbilities(menuItems, hasAbility) } : undefined,
			...restProps,
		};
	});
};
