import { useMatch } from "react-router-dom";

import { BOT_ROUTE, PARTY_SECTION_PATH } from "@/app/constants/routes";
import { LayerType } from "@/app/routing/types";
import { BOT_TYPES, BotType } from "@/shared/routing";

const useCheckLayerMatch = (path: string) => {
	const route = useMatch(path);

	return Boolean(route);
};

const useCheckBotLayerMatch = () => {
	const route = useMatch(BOT_ROUTE);

	if (!route) return false;

	const { params } = route;

	const { botType } = params;

	if (!botType) return false;

	const isBotLayer = BOT_TYPES.includes(botType as BotType);

	return isBotLayer;
};

export const useGetNavigationLayer = (): LayerType => {
	const isPartyLayer = useCheckLayerMatch(PARTY_SECTION_PATH);

	const isBotLayer = useCheckBotLayerMatch();

	if (isBotLayer) return "bot";

	if (isPartyLayer) return "party";

	return "main";
};
