import {
	BotType,
	GetBotLinkProps,
	getBotLink,
	getPartySectionLink,
	PartyPage,
} from "@/shared/routing";

import { CrumbHandle, IMenuItem, IPathParams, IRouteConfig } from "./types";

interface GetPartyMenuItemProps extends Pick<IMenuItem, "title" | "icon" | "indicationName"> {
	section: PartyPage;
}

export const getPartyMenuItem = ({ section, ...restParams }: GetPartyMenuItemProps): IMenuItem => ({
	...restParams,
	to: ({ party }: IPathParams) => getPartySectionLink({ partySection: section, party }),
});

interface GetBotMenuItemProps<T extends BotType>
	extends Pick<IMenuItem, "title" | "icon" | "indicationName">,
		Pick<GetBotLinkProps<T>, "botType" | "botTab"> {}

export const getBotMenuItem = <T extends BotType>({
	botType,
	botTab,
	...restParams
}: GetBotMenuItemProps<T>): IMenuItem => ({
	...restParams,
	to: ({ party, uuid }: IPathParams) => getBotLink({ botType, botTab, party, uuid }),
});

export const getDefaultCrumbConfig = (path: string): CrumbHandle => ({
	crumb: {
		path,
		title: path,
	},
});

export const removeAntLinkStyles = () => {
	for (const styleSheet of document.styleSheets) {
		if (
			styleSheet.ownerNode?.textContent?.includes("a:hover{color:var(--ant-color-link-hover);}")
		) {
			styleSheet.ownerNode.remove();
			break;
		}
	}
};

export const filterHiddenRoutes = (routes: IRouteConfig[]) =>
	routes.filter(({ hide }) => hide === undefined || !hide);

export const filterMenuItemsRoutes = (routes: IRouteConfig[]) =>
	routes.filter(({ menuItem, hide }) => !!menuItem && (hide === undefined || !hide));
