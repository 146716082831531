import { BotLayerParams, BotType, GetBotLinkProps, GetPartyLinkProps } from "./types";

export const getBotRoutePath = ({ botType }: BotLayerParams) => `${botType}/:uuid/`;

export const getBotLink = <T extends BotType>({
	party,
	botType,
	uuid,
	botTab,
}: GetBotLinkProps<T>) => `/party/${party}/${botType}/${uuid}${botTab ? `/${botTab}` : ""}`;

export const getPartySectionLink = ({ party, partySection }: GetPartyLinkProps) =>
	`/party/${party}/${partySection}`;
