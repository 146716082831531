import { Suspense } from "react";
import { RouteObject, useRoutes } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { PermissionWrapper } from "@/features/check-abilities";
import { useUserState } from "@/shared/stores/app-state";
import { ErrorBoundary } from "@/shared/ui/ui-error-boundary";

import { FallbackSpinner } from "./fallback-spinner";
import { AUTH_ROUTES_SCHEME, ROUTES_SCHEME } from "./route-scheme";
import { IRouteConfig } from "./types";
import { filterHiddenRoutes } from "./utils";

const getRoutes = (routes: IRouteConfig[]): RouteObject[] => {
	const filteredRoutes = filterHiddenRoutes(routes);

	return filteredRoutes.map(({ path, element, ability, children, ...restProps }) => {
		const wrappedElement = element && (
			<ErrorBoundary>
				{ability ? <PermissionWrapper abilityName={ability}>{element}</PermissionWrapper> : element}
			</ErrorBoundary>
		);

		const childRoutes = children ? getRoutes(children) : undefined;

		return {
			path,
			element: wrappedElement,
			children: childRoutes,
			...restProps,
		};
	});
};

export const routesItems = getRoutes(ROUTES_SCHEME);
const authRoutesItems = getRoutes(AUTH_ROUTES_SCHEME);

export const Routing = observer(() => {
	const { isLoggedIn } = useUserState();

	const Routers = () => useRoutes(routesItems);
	const AuthRoutes = () => useRoutes(authRoutesItems);

	return (
		<Suspense fallback={<FallbackSpinner />}>{isLoggedIn ? <Routers /> : <AuthRoutes />}</Suspense>
	);
});
