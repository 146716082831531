import { useMemo } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ConfigProvider, Tooltip } from "antd";
import dayjs from "dayjs";

import { useGetToken } from "@/shared/hooks/use-get-token";

import { Timer } from "./timer";
import s from "./clock.module.scss";

const UTCTooltip = () => {
	const { token } = useGetToken();
	const { colorPrimary, colorPrimaryText, colorPrimaryBg } = token;

	return (
		<ConfigProvider
			theme={{
				token: {
					colorTextLightSolid: colorPrimaryText,
					colorBgSpotlight: colorPrimaryBg,
					fontSize: 10,
				},
			}}
		>
			<Tooltip
				placement="bottom"
				title="All times displayed and stored on this platform are in UTC!"
			>
				<InfoCircleOutlined style={{ color: colorPrimary }} />
			</Tooltip>
		</ConfigProvider>
	);
};

export const Clock = () => {
	const utc = useMemo(() => `UTC${dayjs().format("Z").slice(0, 3).replace(/0/g, "")}`, []);

	return (
		<div data-testid="clock" className={s.clock}>
			<p data-testid="value" className={s.value}>
				<Timer />
				<UTCTooltip />
				<span data-testid="utc" className={s.utc}>
					{utc}
				</span>
			</p>
		</div>
	);
};
