import { Location } from "history";

import { buildQueryUrl } from "..";

import { validateRedirectUrl } from "./validate-redirect-url";

const REDIRECT_QUERY_PARAM = "redirect";

export const getRedirectUrlParam = (params: URLSearchParams) => {
	const redirectUrl = params.get(REDIRECT_QUERY_PARAM);

	if (!redirectUrl) {
		return null;
	}

	const decodedRedirectUrl = decodeURIComponent(redirectUrl);

	return decodedRedirectUrl;
};

const setRedirectUrlParam = (baseUrl: string, redirectUrl: string) => {
	const encodedRedirectUrl = encodeURIComponent(redirectUrl);

	const url = buildQueryUrl(baseUrl, { [REDIRECT_QUERY_PARAM]: encodedRedirectUrl });

	return url;
};

/**
 * Creates a redirect parameter URL based on the provided base URL and location.
 * @param baseUrl - The base URL to be used for the redirect parameter.
 * @param location - The location object from the history package.
 * @returns The redirect parameter URL if redirect url is not restricted, otherwise @baseUrl without changes.
 */
export const createRedirectParamUrl = (baseUrl: string, location: Location) => {
	const redirectUrl = location.pathname + location.search;

	const validatedRedirectUrl = validateRedirectUrl(redirectUrl);

	if (!validatedRedirectUrl) {
		return baseUrl;
	}

	const redirectParamUrl = setRedirectUrlParam(baseUrl, validatedRedirectUrl);

	return redirectParamUrl;
};
