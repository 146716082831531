import { useLocalObservable } from "mobx-react-lite";

import { createContextProvider } from "@/shared/providers/utils/create-context-provider";

import { NavigationMenuStore } from "..";

const NavigationMenuStoreCreator = () => useLocalObservable(() => new NavigationMenuStore());

const { Context: NavigationMenuContext, Provider: NavigationMenuProvider } =
	createContextProvider<NavigationMenuStore>({ stateCreator: NavigationMenuStoreCreator });

export { NavigationMenuContext, NavigationMenuProvider };
