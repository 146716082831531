import { RollbackOutlined } from "@ant-design/icons";
import { Button } from "antd";

import { LayerType } from "@/app/routing/types";

import { useMoveLayer } from "./hooks";
import s from "./back-button.module.scss";

interface BackLayerButtonProps {
	layer: LayerType;
}

export const BackLayerButton = ({ layer }: BackLayerButtonProps) => {
	const moveLayer = useMoveLayer(layer);

	return (
		<Button
			type="text"
			icon={<RollbackOutlined />}
			className={s.backButton}
			onClick={() => moveLayer()}
		>
			Back
		</Button>
	);
};
