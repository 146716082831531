import { responseHandler } from "@/shared/api/fetch/response-handler";
import { ApiModules } from "@/shared/api/types";
import { getApiUrl } from "@/shared/api/utils/get-api-url";

import { LiquidGridSettings, NewLiquidGridSettings } from "./types";

export const getLiquiditySettings = async (bot_uuid: string) =>
	responseHandler.handler<LiquidGridSettings[]>({
		url: getApiUrl(ApiModules.Grid, `?bot-uuid=${bot_uuid}`, true),
		method: "GET",
	});

export const setLiquiditySettings = async (data: NewLiquidGridSettings) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.Grid, ""),
		method: "POST",
		data,
	});

export const startLiquidity = async (id: number) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.Grid, `/${id}/start`),
		method: "POST",
	});

export const stopLiquidity = async (id: number) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.Grid, `/${id}/stop`),
		method: "POST",
	});

export const changeLiquidity = async (id: number, data: NewLiquidGridSettings) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.Grid, `/${id}`),
		method: "PUT",
		data,
	});
