import { MAIN_PAGES_ROUTES_SCHEME } from "@/app/routing/route-scheme";
import { filterMenuItemsRoutes } from "@/app/routing/utils";

import { useGetNavigationList } from "../shared/hooks/use-get-navigation-list";

export const MainNavigationList = () => {
	const menuItems = filterMenuItemsRoutes(MAIN_PAGES_ROUTES_SCHEME);

	const list = useGetNavigationList(menuItems);

	return list;
};
