import { useParams } from "react-router-dom";

import {
	CEX_ROUTES_SCHEME,
	DEX_NE_ROUTES_SCHEME,
	DEX_ROUTES_SCHEME,
	DEX_V2_ROUTES_SCHEME,
} from "@/app/routing/route-scheme";
import { IRouteConfig } from "@/app/routing/types";
import { filterMenuItemsRoutes } from "@/app/routing/utils";
import { BotType, BotURLQueryParams } from "@/shared/routing";

import { useGetNavigationList } from "../shared/hooks/use-get-navigation-list";

interface BotNavigationListProps {
	botType: BotType;
}

const MENU_ITEMS_MAP: Record<BotType, IRouteConfig[]> = {
	CEX: CEX_ROUTES_SCHEME[0].children || [],
	DEX: DEX_ROUTES_SCHEME[0].children || [],
	DEX_V2: DEX_V2_ROUTES_SCHEME[0].children || [],
	DEX_NE: DEX_NE_ROUTES_SCHEME[0].children || [],
};

const useGetPathParams = () => {
	const { party, uuid } = useParams<keyof BotURLQueryParams>() as BotURLQueryParams;

	return { party, uuid };
};

export const BotNavigationList = ({ botType }: BotNavigationListProps) => {
	const menuItems = filterMenuItemsRoutes(MENU_ITEMS_MAP[botType]);
	const pathParams = useGetPathParams();

	const list = useGetNavigationList(menuItems, pathParams);

	return list;
};
