import { ReactNode, useEffect } from "react";

import { MOBILE_DEVICE_WIDTH } from "@/shared/constants/media-query";
import { useGeneralState } from "@/shared/stores/app-state";

export const DeviceProvider = ({ children }: { children: ReactNode }) => {
	const { setIsMobile } = useGeneralState();

	useEffect(() => {
		const media = window && window.matchMedia(MOBILE_DEVICE_WIDTH);

		media.addEventListener("change", () => {
			setIsMobile(media.matches);
		});

		return () =>
			media.removeEventListener("change", () => {
				setIsMobile(media.matches);
			});
	}, []);
	return children;
};
