/* eslint-disable func-names */
import {
	addMethod,
	AnyObject,
	AnySchema,
	Flags,
	Maybe,
	Message,
	number,
	NumberSchema,
	Schema,
	SchemaMetadata,
} from "yup";

import { isDefined } from "@/shared/types/type-from-gotbit";

import { defaultRequired, requiredNumber } from "./validations";

type AnyTuple = [unknown, ...unknown[]];

addMethod(number, "multipleOf", function (this: NumberSchema, base: number, message?: Message) {
	return this.test({
		name: "multipleOf",
		// eslint-disable-next-line no-template-curly-in-string
		message: message || "${path} must be a multiple of ${base}",
		params: { base },
		test: (value) => isDefined(value) && value % base === 0,
	});
});

addMethod(Schema, "defaultRequired", function (this: AnySchema, message?: Message) {
	return defaultRequired(this, message);
});

addMethod(number, "requiredNumber", function (this: NumberSchema, message?: Message) {
	return requiredNumber(this, message);
});

declare module "yup" {
	interface Schema<TType = any, TContext = any, TDefault = any, TFlags extends Flags = ""> {
		meta<T extends SchemaMetadata>(obj: T): this;
	}

	interface NumberSchema<TType extends Maybe<number>, TContext, TDefault, TFlags extends Flags> {
		multipleOf(base: number, message?: Message): this;

		requiredNumber(
			message?: Message
		): ReturnType<typeof requiredNumber<TType, TContext, TDefault, TFlags>>;

		defaultRequired(
			message?: Message
		): ReturnType<NumberSchema<TType, TContext, TDefault, TFlags>["required"]>;
	}

	interface StringSchema<TType extends Maybe<string>, TContext, TDefault, TFlags extends Flags> {
		defaultRequired(
			message?: Message
		): ReturnType<StringSchema<TType, TContext, TDefault, TFlags>["required"]>;
	}

	interface ObjectSchema<TIn extends Maybe<AnyObject>, TContext, TDefault, TFlags extends Flags> {
		defaultRequired(
			message?: Message
		): ReturnType<ObjectSchema<TIn, TContext, TDefault, TFlags>["required"]>;
	}

	interface ArraySchema<
		TIn extends any[] | null | undefined,
		TContext,
		TDefault,
		TFlags extends Flags,
	> {
		defaultRequired(
			message?: Message
		): ReturnType<ArraySchema<TIn, TContext, TDefault, TFlags>["required"]>;
	}

	interface BooleanSchema<TType extends Maybe<boolean>, TContext, TDefault, TFlags extends Flags> {
		defaultRequired(
			message?: Message
		): ReturnType<BooleanSchema<TType, TContext, TDefault, TFlags>["required"]>;
	}

	interface DateSchema<TType extends Maybe<Date>, TContext, TDefault, TFlags extends Flags> {
		defaultRequired(
			message?: Message
		): ReturnType<DateSchema<TType, TContext, TDefault, TFlags>["required"]>;
	}

	interface MixedSchema<TType, TContext, TDefault, TFlags extends Flags> {
		defaultRequired(
			message?: Message
		): ReturnType<MixedSchema<TType, TContext, TDefault, TFlags>["required"]>;
	}

	interface TupleSchema<TType extends Maybe<AnyTuple>, TContext, TDefault, TFlags extends Flags> {
		defaultRequired(
			message?: Message
		): ReturnType<TupleSchema<TType, TContext, TDefault, TFlags>["required"]>;
	}
}
