import { useEffect } from "react";

import { useAbilitiesState } from "@/shared/stores/app-state";

export const useConnectPartyAbilities = (party: string | null) => {
	const { setParty, resetParty } = useAbilitiesState();

	useEffect(() => {
		setParty(party);

		return () => {
			resetParty();
		};
	}, [setParty, resetParty, party]);
};
