import { useRef } from "react";

type RefType<T> = { fn: T };

export const useConstant = <T>(fn: () => T): T => {
	const ref = useRef<RefType<T>>();

	if (!ref.current) {
		ref.current = { fn: fn() };
	}

	return ref.current.fn;
};
