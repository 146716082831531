import { useEffect } from "react";
import { App as AppContainer, ConfigProvider, Layout, ThemeConfig } from "antd";
import { observer } from "mobx-react-lite";

import dark from "@/app/styles/dark.json";
import light from "@/app/styles/light.json";
import { Aside } from "@/app/template/aside";
import { Footer } from "@/app/template/footer";
import { Header } from "@/app/template/header";
import s from "@/app/template/template.module.scss";
import { useConnectPartyAbilities } from "@/features/check-abilities";
import { useGeneralState, useUserState } from "@/shared/stores/app-state";
import { ColorTheme } from "@/shared/types/types";
import { UIModalConsent } from "@/shared/ui/ui-modal-consent";
import { ToasterInit } from "@/shared/ui/ui-toaster";

import { useGetPartyFromUrl } from "./hooks";
import { Routing } from "./routing";
import { StyleColorScheme } from "./style-color-scheme";

type ThemeTokenMap = Record<ColorTheme, ThemeConfig>;

const themes: ThemeTokenMap = { light, dark };

export const App = observer(() => {
	const { theme, isMobile, init } = useGeneralState();
	const { isLoggedIn } = useUserState();

	const party = useGetPartyFromUrl();

	useConnectPartyAbilities(party);

	useEffect(() => {
		init();
	}, []);

	return (
		<ConfigProvider theme={{ cssVar: true, ...themes[theme] }}>
			<AppContainer data-testid="template" className={s.template}>
				<StyleColorScheme />

				<Header />

				<main data-testid="main" className={s.main}>
					{isLoggedIn && !isMobile && <Aside data-testid="aside" />}

					<Layout data-testid="content" className={s.content}>
						<Routing />
					</Layout>
				</main>

				{isLoggedIn && <Footer />}

				<ToasterInit theme={theme} />

				<UIModalConsent />
			</AppContainer>
		</ConfigProvider>
	);
});
