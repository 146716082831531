import { useParams } from "react-router-dom";

import { PARTY_ROUTES_SCHEME } from "@/app/routing/route-scheme";
import { filterMenuItemsRoutes } from "@/app/routing/utils";
import { PartyQueryParams } from "@/shared/routing";

import { useGetNavigationList } from "../shared/hooks/use-get-navigation-list";

export const PartyNavigationList = () => {
	const menuItems = filterMenuItemsRoutes(PARTY_ROUTES_SCHEME);

	const { party } = useParams<keyof PartyQueryParams>() as PartyQueryParams;

	return useGetNavigationList(menuItems, { party });
};
