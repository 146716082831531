import { makeAutoObservable } from "mobx";

import { IndicationName } from "@/app/routing/types";
import {
	getCEXBot,
	getLiquiditySettings,
	getMultiGridUUID,
	getMultiGrinderStatus,
} from "@/shared/api/endpoints";
import { CEXRoutes } from "@/shared/routing";
import { StatusColorNames } from "@/shared/types/status-colors-types";
import { stringDateToUnix } from "@/shared/utils/date-utils";
import { logError } from "@/shared/utils/logger";
import { getCEXBotStatusColorName } from "@/shared/utils/status/get-cex-status-color";
import { getStatusColorName } from "@/shared/utils/status/get-status-color";

import { geCexSettingsMsg, getLastUpdateMsg } from "./utils";

export interface InfoIndication {
	statusName: StatusColorNames | null;
	message?: string;
}

interface LoadIndicationProps {
	allIndicationInCurrentMenu: IndicationName[];
	party?: string;
	uuid?: string;
	currentTab?: string;
}

interface ShouldFetchDataProps {
	indicationKey: IndicationName;
	currentIdentifier?: string;
	lastIdentifier?: string;
	currentTab?: string;
	skipTab: string;
	allIndicationInCurrentMenu: IndicationName[];
}

interface IndicationMap extends Partial<Record<IndicationName, InfoIndication>> {}

export class NavigationMenuStore {
	private _indicationMap: IndicationMap = {};

	private _lastBotId?: string;

	private _lastParty?: string;

	constructor() {
		makeAutoObservable(this);
	}

	get indicationMap() {
		return this._indicationMap;
	}

	getIndication = (indicationName?: IndicationName) =>
		indicationName && this.indicationMap?.[indicationName];

	loadIndication = ({
		allIndicationInCurrentMenu,
		party,
		uuid,
		currentTab,
	}: LoadIndicationProps) => {
		if (
			this._shouldFetchData({
				indicationKey: "cexSettings",
				currentIdentifier: uuid,
				lastIdentifier: this._lastBotId,
				currentTab,
				skipTab: CEXRoutes.Settings,
				allIndicationInCurrentMenu,
			})
		)
			this._fetchCexSettings(uuid as string);

		if (
			this._shouldFetchData({
				indicationKey: "cexGrid",
				currentIdentifier: uuid,
				lastIdentifier: this._lastBotId,
				currentTab,
				skipTab: CEXRoutes.Grid,
				allIndicationInCurrentMenu,
			})
		)
			this._fetchCexGrid(uuid as string);

		if (
			this._shouldFetchData({
				indicationKey: "multigrid",
				currentIdentifier: party,
				lastIdentifier: this._lastParty,
				currentTab,
				skipTab: CEXRoutes.Multigrid,
				allIndicationInCurrentMenu,
			})
		)
			this._fetchMultiGrid(party as string);

		this._updateLastLayerInfo(party, uuid);
	};

	private _shouldFetchData = ({
		indicationKey,
		currentIdentifier,
		lastIdentifier,
		currentTab,
		skipTab,
		allIndicationInCurrentMenu,
	}: ShouldFetchDataProps) => {
		const isIndicationInCurrentMenu = allIndicationInCurrentMenu.includes(indicationKey);
		if (!isIndicationInCurrentMenu) {
			this._setIndicationMap(indicationKey);
			return false;
		}
		const hasData = Boolean(this.indicationMap[indicationKey]);

		return (
			currentIdentifier &&
			(!hasData || currentIdentifier !== lastIdentifier) &&
			currentTab !== skipTab
		);
	};

	private _updateLastLayerInfo = (party?: string, uuid?: string) => {
		this._lastBotId = uuid;
		this._lastParty = party;
	};

	private _setIndicationMap = (key: IndicationName, value?: InfoIndication) => {
		this._indicationMap[key] = value;
	};

	private _fetchCexSettings = async (uuid: string) => {
		try {
			const { isError, data } = await getCEXBot(uuid);

			if (!isError) {
				const { status, timeDontWork, message } = data || {};
				const msg = geCexSettingsMsg(timeDontWork, message);
				const statusColor = status ? getCEXBotStatusColorName(status, timeDontWork) : null;

				this._setIndicationMap("cexSettings", { statusName: statusColor, message: msg });
			}
		} catch (error) {
			logError(error);
		}
	};

	private _fetchCexGrid = async (uuid: string) => {
		try {
			const { data, isError } = await getLiquiditySettings(uuid);

			if (!isError) {
				const { state, updatedAt } = data[0] || {};
				const statusColor = state ? getStatusColorName("grid", state) : null;
				this._setIndicationMap("cexGrid", {
					statusName: statusColor,
					message: updatedAt && getLastUpdateMsg(stringDateToUnix(updatedAt)),
				});
			}
		} catch (error) {
			logError(error);
		}
	};

	private _fetchMultiGrid = async (party: string) => {
		const multiGrinderId = await this._fetchMultiGrinderId(party);

		if (multiGrinderId) this._fetchMultiGridStatus(multiGrinderId);
		else this._setIndicationMap("multigrid", { statusName: null });
	};

	private _fetchMultiGrinderId = async (party: string) => {
		try {
			const { isError, data } = await getMultiGridUUID(party);

			if (!isError) return data[0];
		} catch (error) {
			logError(error);
		}
	};

	private _fetchMultiGridStatus = async (uuid: string) => {
		try {
			const { isError, data } = await getMultiGrinderStatus(uuid);

			if (!isError) {
				const { status, lastUpdateAt } = data;
				const statusColor = status ? getStatusColorName("multigrid", status) : null;

				this._setIndicationMap("multigrid", {
					statusName: statusColor,
					message: getLastUpdateMsg(lastUpdateAt),
				});
			}
		} catch (error) {
			logError(error);
		}
	};
}
