import { useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import classNames from "classnames";

import { PARTY_SECTION_PATH } from "@/app/constants/routes";
import {
	CEX_ROUTES_SCHEME,
	DEX_NE_ROUTES_SCHEME,
	DEX_ROUTES_SCHEME,
	DEX_V2_ROUTES_SCHEME,
} from "@/app/routing/route-scheme";
import { BotType } from "@/shared/routing";

import { AsideContext } from "../model/navigation-menu/context/context";
import { NavigationMenuProvider } from "../model/navigation-menu/context/navigation-menu";
import { useGetNavigationLayer } from "../shared/hooks/use-get-navigation-layer";
import { getBotFullRoutePath } from "../shared/utils";

import { BackLayerButton } from "./back-layer-button";
import { BotNavigationList } from "./bot-navigation-list";
import { MainNavigationList } from "./main-navigation-list";
import { PartyNavigationList } from "./party-navigation-list";
import s from "./aside.module.scss";

const MAIN_SECTION_PATH = "/*";

interface NavPath {
	path: string;
	botType: BotType;
}

export const BOT_NAV_PARAMS: NavPath[] = [
	{ path: getBotFullRoutePath(CEX_ROUTES_SCHEME[0].path), botType: "CEX" },
	{ path: getBotFullRoutePath(DEX_ROUTES_SCHEME[0].path), botType: "DEX" },
	{ path: getBotFullRoutePath(DEX_V2_ROUTES_SCHEME[0].path), botType: "DEX_V2" },
	{ path: getBotFullRoutePath(DEX_NE_ROUTES_SCHEME[0].path), botType: "DEX_NE" },
];

interface AsideProps {
	afterClick?: () => void;
}

export const Aside = ({ afterClick }: AsideProps) => {
	const layer = useGetNavigationLayer();

	const eventsHandlers = useMemo(() => ({ afterClick }), [afterClick]);

	return (
		<AsideContext.Provider value={eventsHandlers}>
			<NavigationMenuProvider>
				<aside data-testid="aside" className={classNames(s.aside)}>
					{layer !== "main" ? <BackLayerButton layer={layer} /> : null}
					<Routes>
						<Route path={PARTY_SECTION_PATH} element={<PartyNavigationList />} />

						{BOT_NAV_PARAMS.map(({ path, botType }) => (
							<Route key={path} path={path} element={<BotNavigationList botType={botType} />} />
						))}

						<Route path={MAIN_SECTION_PATH} element={<MainNavigationList />} />
					</Routes>
				</aside>
			</NavigationMenuProvider>
		</AsideContext.Provider>
	);
};
